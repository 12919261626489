import React, { useContext } from 'react'

import logo from './assets/logo.png'
import web3authLogo from './assets/web3auth-logo.png'
import whiteLogo from './assets/white-logo.png'
import { AuthProviderContext } from './AuthProvider/AuthProvider'
import MintNft from './components/MintNft'

const App = () => {
	const { search } = window.location
	const params = new URLSearchParams(search)
	const tokenId = params.get('tokenId')

	const { user, login, logout } = useContext(AuthProviderContext)

	return (
		<div className="bg-violet-900">
			<img
				className="absolute mt- ml-0 w-40"
				src={whiteLogo}
				alt="logo"
			/>
			<div className="flex flex-col h-screen w-screen justify-center items-center py-6 bg-violet-900">
				{user ? (
					<div className="py-10 px-10 w-full flex flex-col items-center container">
						<MintNft tokenId={tokenId} />

						<p className="font-semibold text-base text-white mt-4">
							Logged as:
							{' '}
							{user?.email}
						</p>
						<button
							onClick={logout}
							type="button"
							className="flex w-1/2 justify-center font-bold text-violet-900 py-2 px-4 mt-3 rounded-lg bg-white"
						>
							Logout
						</button>
					</div>
				) : (
					<div className="flex h-96 justify-around flex-col bg-white rounded-lg px-20 pt-1 py-10">
						<div className="flex flex-col items-center ">
							<img
								className="w-80 self-center"
								src={logo}
								alt="logo"
							/>
							<p className="font-semibold text-lg text-violet-900">
								Login. Claim your NFT.
							</p>
						</div>
						<button
							onClick={login}
							type="button"
							className="flex flex-row items-center justify-center text-violet-900 py-2 px-4 rounded-lg border border-violet-900"
						>
							<img
								className="w-5 h-5"
								src={web3authLogo}
								alt="google"
							/>
							<p className="pl-2 py-1">Log in with Web3Auth</p>
						</button>
					</div>
				)}
			</div>
		</div>
	)
}
export default App
