/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */

import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'

import { getAvailableTokens, redeemNft } from '../../api'
import { AuthProviderContext } from '../../AuthProvider/AuthProvider'
import { getMetadataUri } from '../../helpers/ethers'
import { Nft } from '../../types/Nft'
import EmptyState from '../EmptyState'
import Spinner from '../Spinner'

interface Props {
	tokenId: string | null;
}

const MintNft = ({ tokenId }: Props) => {
	const [nft, setNft] = useState<Nft | null>(null)
	const [txHash, setTxHash] = useState('')
	const [loading, setLoading] = useState(true)
	const [isMinting, setIsMinting] = useState(false)
	const [minted, setMinted] = useState(false)
	const { user, appPublicKey, getUserAddress } = useContext(AuthProviderContext)

	const getNft = async () => {
		try {
			setLoading(true)
			if (user?.email) {
				const availableTokens = await getAvailableTokens(user?.email)
				if (tokenId) {
					const nftFound = availableTokens.find((n) => n.tokenId === +tokenId)
					if (nftFound) {
						const uri = await getMetadataUri(nftFound.tokenId)
						const res = await axios.get(uri)
						setNft({ ...res.data })
					} else {
						setNft(null)
					}
				} else {
					console.log('no tokenid')
				}
			}
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	const handleClaim = async () => {
		setIsMinting(true)
		try {
			const address = await getUserAddress()
			const appPubKey = await appPublicKey()
			if (!appPubKey || !user?.idToken || !tokenId || !address) {
				throw new Error('Error getting user credentials')
			}
			const res = await redeemNft(
				user.idToken,
				appPubKey,
				tokenId,
				address,
			)
			setTxHash(res.transactionHash)
			setMinted(true)
		} catch (error) {
			console.log(error)
		} finally {
			setIsMinting(false)
		}
	}
	useEffect(() => {
		getNft()
	}, [])

	if (loading) return <>Loading...</>

	if (!nft) return <EmptyState />

	return (
		<div className="flex flex-row w-5/6 bg-white rounded-lg p-10">
			<div className="flex justify-between flex-col w-1/2 my-4 mx-5">
				<div>
					<div className="font-bold text-violet-800 text-4xl mb-4">
						{nft.name}
					</div>
					<div>
						<p className="text-xl select-none mt-4">
							{nft.description}
						</p>
					</div>
				</div>
				{minted ? (
					<div>
						<button
							type="button"
							onClick={handleClaim}
							className="font-bold mt-2 w-full bg-violet-800 text-white rounded-lg p-2 shadow-lg opacity-50"
							disabled
						>
							Minted
						</button>
					</div>
				) : isMinting ? (
					<Spinner />
				) : (
					<div>
						<button
							type="button"
							onClick={handleClaim}
							className="font-bold mt-2 w-full bg-violet-800 text-white rounded-lg p-2 shadow-lg"
							disabled={isMinting}
						>
							Claim your NFT
						</button>
					</div>
				)}
				{minted && txHash ? (
					<div>
						<p className="break-words text-violet-800 mt-3">
							Congratulations, now you have this NFT!
						</p>
						<p className="break-words text-violet-800 mt-3">
							View your transaction
							{' '}
							<a
								className="break-words text-violet-900 font-bold"
								href={`https://mumbai.polygonscan.com/tx/${txHash}`}
								target="_blank"
								rel="noreferrer"
							>
								here
							</a>
						</p>
					</div>
				) : null}
			</div>
			<div className="flex flex-col w-1/2 my-4 mx-5 justify-center">
				<img
					src={nft.image}
					alt={nft.name}
					className="object-contain h-full"
				/>
			</div>
		</div>
	)
}

export default MintNft
