import { ethers } from 'ethers'

import AirdropAbi from '../../contract/Airdrop.json'

const alchemyKey = process.env.REACT_APP_ALCHEMY_API_KEY as string
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS as string
const chain = process.env.REACT_APP_CHAIN

export const getAccount = async (privateKey: string): Promise<string> => {
	try {
		const provider = new ethers.AlchemyProvider(chain, alchemyKey)
		const wallet = new ethers.Wallet(privateKey, provider)

		const address = await wallet.getAddress()

		return address
	} catch (error) {
		return ''
	}
}

export const getMetadataUri = async (tokenId: number): Promise<string> => {
	try {
		const provider = new ethers.AlchemyProvider(chain, alchemyKey)
		const contract = new ethers.Contract(contractAddress, AirdropAbi, provider)

		const uri = await contract.uri(tokenId)

		return uri
	} catch (error) {
		return ''
	}
}
