import React from 'react'

import NotFoundPng from '../../assets/not-found.png'

const EmptyState = () => (
	<div className="flex flex-col items-center justify-center h-3/4">
		<img
			src={NotFoundPng}
			alt="Not found"
			className="object-contain w-3/4 h-3/4"
		/>
		<h2 className="mt-4 mb-2 text-2xl font-semibold text-white">
			No NFT was found
		</h2>
		<p className="text-white">
			Sorry, your email doesn't have any NFT available for mint.
		</p>
	</div>
)

export default EmptyState
