import React, { ReactNode } from 'react'

import { AuthProvider } from './AuthProvider'

interface Props {
  children?: ReactNode;
}

export const Auth = ({ children }: Props) => (
	<AuthProvider>
		{ children }
	</AuthProvider>
)
