import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

interface NftModel {
	tokenId: number;
	owner: string;
}

axios.defaults.baseURL = API_URL

export async function getAvailableTokens(email: string): Promise<NftModel[]> {
	try {
		const response = await axios.get(`/available/${email}`)
		return response.data
	} catch (error) {
		console.error('Error getting available tokens:', error)
		throw error
	}
}

export async function redeemNft(
	authToken: string,
	appPubKey: string,
	tokenId: string,
	address: string,
): Promise<{ transactionHash: string}> {
	try {
		const response = await axios.post(
			`/mint/${tokenId}`,
			{ appPubKey, address },
			{
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			},
		)
		return response.data
	} catch (error) {
		console.error('Error while minting', error)
		throw error
	}
}
